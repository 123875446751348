@tailwind base;
@tailwind components;
@tailwind utilities;



/* src/index.css or src/App.css */
/* @import '/public'; */


@font-face {
    font-family: 'Product_Sans';
    src: url('/public/fonts/ProductSans/ProductSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Product_Sans_Medium';
    src: url('/public/fonts/ProductSans/ProductSans-Medium.ttf') format('truetype');
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap'); */

:root {
    --orange1: #FFF9F5;
    --orange2: #FEE2CC;
    --orange3: #FEB87F;
    --orange4: #FD8E32;
    --orange5: #FD7200;
    --orange6: #E36600;
    --orange7: #CA5B00;

    --green1: #F7FFFB;
    --green2: #E7FBF2;
    --green3: #B9F5D8;
    --green4: #5CE9A6;
    --green5: #17E080;
    --green6: #12B366;
    --green7: #0D864C;

    --primary1: #FCFDFF;
    --primary2: #F8FBFF;
    --primary3: #EEF2F8;
    --primary4: #D7DEE8;
    --primary5: #A1AEBF;
    --primary6: #515F6F;
    --primary7: #071B32;

    --blue1: #F8FDFF;
    --blue2: #E6F5FA;
    --blue3: #9BD7EE;
    --blue4: #50B9E1;
    --blue5: #059CD5;
    --blue6: #048CBF;
    --blue7: #036D95;

    --red1: #FFF5F7;
    --red2: #FFDEE4;
    --red3: #FFB3C1;
    --red4: #FF768E;
    --red5: #FF3559;
    --red6: #B30625;
    --red7: #B30625;


    /* Functional colors */
    --black: #000000;
    --offwhite: #F0F0F0;
    --white: #FFFFFF;


}


html,
body,
#root {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Product_Sans', sans-serif;
    /* font-family: "Roboto", sans-serif; */
    /* font-family: "Fira Sans", sans-serif; */
    /* font-family: "Lato", sans-serif; */
    /* font-family: "Poppins", sans-serif; */
    /* font-family: "Exo 2", sans-serif; */
    /* font-family: "Inter", sans-serif; */
    /* font-family: "Montserrat", sans-serif; */
}



.roboto-font-table {
    font-family: "Roboto", sans-serif;
}

.leaflet-container {
    height: 100%;
    width: 100%;
}

.Product_Sans_Medium {
    font-family: 'Product_Sans_Medium';
    /* font-weight: 500; */
}


.calendar-design {
    font-family: 'Product_Sans';
}

.bold-star-icon {
    stroke-width: .8px;
}



.my-divider::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    /* top: 0; */
    bottom: -10px;
    background: linear-gradient(90deg, #FFFFFF 0%, #17E080 47.71%, #FFFFFF 100%);
    height: 1.6px;
}


.react-datepicker__input-container input {
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    font-weight: 700;
    width: 120px;
}

.rounded-oval {
    background-color: #E7FBF2;
    border: 1px solid #12B366;
    color: #12B366;
    border-radius: 40px;
    padding: 4px 12px;
    display: inline-flex;
    align-items: center;
    height: 23px;
    width: 60px;
    justify-content: space-around;
}


.rounded-oval-blue {
    border: 1px solid #059CDF;
    background-color: #F8FDFF;
    color: #059CDF;
    border-radius: 40px;
    padding: 4px 10px;
    display: inline-flex;
    align-items: center;
    color: #059CDF;
    height: 23px;
    width: 60px;
    justify-content: space-around;
}

.rounded-oval-red {
    border: 1px solid #B30625;
    background-color: #FFDEE4;
    color: #B30625;
    border-radius: 40px;
    padding: 4px 10px;
    display: inline-flex;
    align-items: center;
    height: 23px;
    width: 60px;
    justify-content: space-around;
}


.rounded-oval-dark-green {
    background-color: #E3F9E5;
    /* Light background for dark green */
    border: 1px solid #0C4E28;
    /* Dark green border */
    color: #0C4E28;
    /* Dark green text */
    border-radius: 40px;
    padding: 4px 12px;
    display: inline-flex;
    align-items: center;
    height: 23px;
    width: 60px;
    justify-content: space-around;
}

.rounded-oval-light-green {
    background-color: #E7FBF2;
    /* Light background for light green */
    border: 1px solid #12B366;
    /* Light green border */
    color: #12B366;
    /* Light green text */
    border-radius: 40px;
    padding: 4px 12px;
    display: inline-flex;
    align-items: center;
    height: 23px;
    width: 60px;
    justify-content: space-around;
}

.rounded-oval-amber {
    background-color: #FFF8E1;
    /* Light background for amber */
    border: 1px solid #FFC107;
    /* Amber border */
    color: #FFC107;
    /* Amber text */
    border-radius: 40px;
    padding: 4px 10px;
    display: inline-flex;
    align-items: center;
    height: 23px;
    width: 60px;
    justify-content: space-around;
}

.rounded-oval-red {
    border: 1px solid #B30625;
    background-color: #FFDEE4;
    color: #B30625;
    border-radius: 40px;
    padding: 4px 10px;
    display: inline-flex;
    align-items: center;
    height: 23px;
    width: 60px;
    justify-content: space-around;
}


.icon {
    color: #12B366;
    font-size: 1.25rem;
}

.icon-blue {
    color: #059CDF;
    font-size: 1.25rem;
}

.icon-red {
    color: #B30625;
    font-size: 1.25rem;

}





/* Custom scrollbar styles */
.custom-scrollbar::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #D7DEE8;
    border-radius: 20px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #f1f1f1;
}


.custom-scrollbar {
    scrollbar-width: 2px;

    scrollbar-color: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-button {
    display: none;
}






.react-tooltip {
    background-color: #fff;
    /* Background color */
    color: #333;
    /* Text color */
    border: 1px solid #ccc;
    /* Border color */
    border-radius: 5px;
    /* Rounded corners */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    /* Shadow effect */
}



/* input[type="checkbox"].custom-checkbox-color {
    appearance: none;
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border: 1px solid #A1AEBF;
    border-radius: 4px;
    cursor: pointer;
} */

/* input[type="checkbox"].custom-checkbox-color:checked {
    background-color: #FD7200;
    border: none;
    outline: none;
    position: relative;
} */

/* input[type="checkbox"].custom-checkbox-color:checked::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 14px;
   
    height: 14px;
   
    background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"%3E%3Cpath d="M20 6 9 17l-5-5"/%3E%3C/svg%3E') no-repeat center center;
    background-size: contain;
} */

.line-clamp-5 {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.line-clamp-none {
    display: block;
}




.leaflet-control-zoom,
.leaflet-bar,
.leaflet-control {
    visibility: hidden !important;
}

.leaflet-control-attribution .leaflet-control {
    visibility: hidden;
}

.zoom-control {
    position: absolute;
    top: 8px;
    left: 310px;
    z-index: 10;
    display: flex;
    flex-direction: row;
    width: 100px;
    justify-content: space-around;

}

.zoom-control button {
    margin: 2px 0;
    padding: 7px 10px;
    background-color: white;
    border: 1px solid white;
    border-radius: 4px;
    cursor: pointer;
}

.fullscreen-button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
    padding: 5px 10px;
    background-color: white;
    border: 1px solid white;
    cursor: pointer;
    border-radius: 4px;
}

.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    background-color: white;
    border-radius: 0;

}

/* .parent-cls .my-selected-range:first-child>div */
.selected-range {
    position: relative;
}

.selected-range::after {
    /* content: ''; */
    background-color: var(--blue6);
    right: -24px;
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0px;
    z-index: 2;
    border-radius: 50%;
}


.selected-range:first-child:before {
    content: '';
    background-color: #ffedd5;
    right: -24px;
    position: absolute;
    width: 38px;
    height: 39px;
    top: 0.8px;
    z-index: 0;
}

.selected-range:last-child:before {

    content: '';
    background-color: #ffedd5;
    left: -1px;
    position: absolute;
    width: 26px;
    height: 39px;
    top: 0.8px;
    z-index: 0;
}

.formated-date {
    position: relative;
    display: inline-block;
    width: 39px;
    height: 39px;
}

.formated-date2 {
    /* position: relative;
    display: inline-block; */
    width: 39px;
    height: 39px;
}

/* .my-form-control .MuiInputBase-input {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.my-form-control .MuiInputBase-input:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
} */


/* .css-yr79tx-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    background-color: white;
} */

.MuiOutlinedInput-root {
    background-color: #fff !important;
}

.my-dropdownd {
    border: 2px solid;
}

.my-dropdownd:hover {
    border: 2px solid var(--orange4) !important;
}


.custom-textfield .MuiInputBase-input:-webkit-autofill,
.custom-textfield .MuiInputBase-input:-webkit-autofill:hover,
.custom-textfield .MuiInputBase-input:-webkit-autofill:focus,
.custom-textfield .MuiInputBase-input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: inherit !important;
}


.shadow-box {
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.3);
}

.active-tb-1012 {
    position: relative;
}

.active-tb-1012::after {
    content: "";
    width: 100%;
    /* margin: auto; */
    /* margin-left: auto;
    margin-right: auto; */
    height: 2px;
    border-radius: 25px;
    background-color: black;
    position: absolute;
    bottom: 0px;
    left: 0px;

}



.layout-container {
    display: flex;
    /* gap: 1%; */
}




.main-container {

    overflow-x: hidden;
}

.truncate-text {
    @apply max-w-[140px] overflow-hidden text-ellipsis whitespace-nowrap;

}